import { Col, Row, Tabs, Badge, Button } from 'antd';
import { connect, FormikValues, FormikContextType } from 'formik';
import React from 'react';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import DeliveryFormPart from './DeliveryFormPart';
import TimeInput from './TimeInput';
import FormRadio from '../../global/Forms/FormRadio';
import FormSelect from '../../global/Forms/FormSelect';
import calendar from '../../../constants/CalendarConstants.json';
import SimpleDivider from '../../global/SimpleDivider';
import {
  ExtendedResources,
  StandardResourceCodeDescription,
  StandardResourceConfig,
} from '../../../../types/resources';
import { ExportBuilderAdvancedTemplate } from '../../../../types/export_builder_advanced';
import { FileNameTemplate } from '../../../../types/channel';
import { namingConventionPreview } from '../../../utils/ChannelUtils';
import ExportFormatOptions from '../import_export/ExportFormatOptions';
import { FileExportOption } from '../../../../types/import_export';

type FormikProps = {
  formik: FormikContextType<FormikValues>;
};

type ChannelDefaultProps = {
  disabled: boolean;
  canManageAdvancedExportOptions: boolean;
  deliveryFrequencies: any[];
  deliveryTypes: any[];
  ftpModes: any[];
  ftpDirectoryTypes: any[];
  protocolTypes: any[];
  encryptionTypes: any[];
  fileTypes: ExtendedResources[];
  fileNameConfigOptions: StandardResourceCodeDescription[];
  exportTemplatesAdvanced: ExportBuilderAdvancedTemplate[];
  fileNameTemplates: FileNameTemplate[];
  defaultFileNameTemplate: StandardResourceConfig;
  fileExportOptions: FileExportOption[];
  fileTypeName?: string;
  openNamingConventionDrawer: () => void;
  handleFileTypeChange: (filetypeId?: number) => void;
  resetAdvancedOptions: (typeId: number, typeName: string) => void;
} & WithTranslation;

export enum FormSegments {
  FILE = 'FILE',
  SCHEDULE = 'SCHEDULE',
  DELIVERY = 'DELIVERY',
}

class ChannelDefaultSettings extends React.Component<ChannelDefaultProps & FormikProps> {
  getExportFileType = (fileTypeId: number | null) => {
    const { fileExportOptions } = this.props;
    return fileExportOptions.find(fileTypeOption => fileTypeOption.id === fileTypeId);
  };

  render() {
    const {
      t,
      formik,
      deliveryFrequencies,
      deliveryTypes,
      ftpDirectoryTypes,
      protocolTypes,
      fileTypes,
      fileNameTemplates,
      defaultFileNameTemplate,
      fileTypeName,
      canManageAdvancedExportOptions,
    } = this.props;
    const { values, setFieldValue } = formik;

    const getNamingConventionPreviewText = (templateId: number) => {
      const { fileNameTemplates, fileNameConfigOptions } = this.props;
      const template =
        fileNameTemplates.find(template => template.id === templateId) || defaultFileNameTemplate;

      if (template) return namingConventionPreview(template.config, fileNameConfigOptions);
      return '';
    };

    const counts: { [key: string]: any } = {};
    fileTypes.forEach(type => {
      counts[type.id] = values.defBrandPlans.filter((p: any) =>
        p.fileTypes.includes(type.id)
      ).length;
    });

    const deliveryMoment = moment.utc(values.schedule.deliveryTime, 'HH:mm');
    const timeZoneOffset = `${moment.parseZone(moment().format()).format('z')} ${moment().format(
      'Z'
    )} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    return (
      <React.Fragment>
        <div>
          <div className="channel__naming-convention flex">
            <FormSelect
              className="flex-1"
              name="file.fileNameTemplateId"
              values={fileNameTemplates}
              label={t('channel:defaultNamingConvention')}
              placeholder={`${t('common:default')}: ${defaultFileNameTemplate.config}`}
              allowClear
            />
            <Button
              className="channel__naming-convention-button self-end"
              onClick={() => this.props.openNamingConventionDrawer()}
            >
              {t('channel:editNamingConvention')}
            </Button>
          </div>
          <div className="pl-3 text-gray-600 text-xs">
            {getNamingConventionPreviewText(values.file.fileNameTemplateId)}
          </div>

          <SimpleDivider title={t('channel:defaultFileSettings')} />

          <div className="channel__card-container">
            <Tabs
              type="card"
              defaultActiveKey="1"
              onChange={key => this.props.handleFileTypeChange(key ? Number(key) : undefined)}
            >
              {fileTypes.map(type => (
                <Tabs.TabPane
                  tab={
                    <span>
                      {type.name}
                      <Badge
                        count={counts[type.id.toString()]}
                        overflowCount={99999}
                        className="ant-blue-batch"
                        style={{ marginLeft: '5px', marginTop: '-3px' }}
                      />
                    </span>
                  }
                  key={type.id}
                >
                  <ExportFormatOptions
                    handleAdvancedOptions={advancedEnabled => {
                      const advIds = advancedEnabled
                        ? [...new Set([...values.advOptionFileTypeIds, type.id])]
                        : values.advOptionFileTypeIds.filter((id: number) => id !== type.id);
                      setFieldValue('advOptionFileTypeIds', advIds);
                      if (!advancedEnabled) this.props.resetAdvancedOptions(type.id, type.name);
                    }}
                    segment="channel"
                    fileTypeName={type.name}
                    fileTypeId={type.id}
                    exportOptions={this.getExportFileType(type.id)?.export_options || []}
                    canManageAdvancedExportOptions={canManageAdvancedExportOptions}
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
        <div>
          <SimpleDivider title={t('channel:defaultScheduleSettings')} />
          <Row gutter={10}>
            <Col span={9}>
              <FormRadio
                name="schedule.deliveryFrequencyId"
                values={deliveryFrequencies.map(freq => ({
                  ...freq,
                  disabled:
                    [1, 4].includes(Number(values.exportOptions[fileTypeName!].submission_type)) &&
                    freq.id === 1, // (add submission type id )
                }))}
                label={t('channel:deliveryFrequency')}
                required
              />
            </Col>
            {values.schedule.deliveryFrequencyId === 2 && (
              <Col span={3}>
                <FormSelect
                  name="schedule.deliveryDay"
                  values={calendar.days}
                  label={t('channel:deliveryDay')}
                  required
                />
              </Col>
            )}
            {values.schedule.deliveryFrequencyId === 5 && (
              <Col span={4}>
                <FormSelect
                  name="schedule.deliveryMonth"
                  values={calendar.quarters}
                  label={t('channel:deliveryMonth')}
                  required
                />
              </Col>
            )}
            {[3, 5].includes(values.schedule.deliveryFrequencyId) && (
              <Col span={3}>
                <FormSelect
                  name="schedule.deliveryDate"
                  values={[...new Array(28)].map((_, i) => ({
                    id: i + 1,
                    name: i + 1,
                  }))}
                  label={t('channel:deliveryDate')}
                  required
                />
              </Col>
            )}
            {values.schedule.deliveryFrequencyId !== 4 && (
              <Col span={8}>
                <div className="w-auto mr-3 inline-block">
                  <TimeInput
                    name="schedule.deliveryTime"
                    label={t('channel:deliveryTime')}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className="inline-block align-top ml-5">
                  <div className="ant-text-black mb-3">
                    {`${t('channel:localTime')}: (${timeZoneOffset})`}
                  </div>
                  {values.schedule.deliveryFrequencyId === 1 && (
                    <div>{moment(deliveryMoment).local().format('hh:mm a')}</div>
                  )}
                  {values.schedule.deliveryFrequencyId === 2 && (
                    <div>
                      {moment(deliveryMoment)
                        .day(values.schedule.deliveryDay)
                        .local()
                        .format('dddd hh:mm a')}
                    </div>
                  )}
                  {[3, 5].includes(values.schedule.deliveryFrequencyId) && (
                    <div>
                      {moment(deliveryMoment)
                        .date(values.schedule.deliveryDate)
                        .local()
                        .format('[Day:] D - hh:mm a')}
                    </div>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div>
          <SimpleDivider title={t('channel:defaultDeliverySettings')} />
          <DeliveryFormPart
            disabled={false}
            deliveryTypes={deliveryTypes}
            ftpDirectoryTypes={ftpDirectoryTypes}
            protocolTypes={protocolTypes}
            // ftpModes={ftpModes}
            // encryptionTypes={encryptionTypes}
            values={values}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  connect<ChannelDefaultProps, FormikValues>(ChannelDefaultSettings)
);
