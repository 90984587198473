import axios from 'axios';
import debounce from 'lodash/debounce';
import { Dispatch } from 'redux';
import { message } from 'antd';
import { createURL, generateUUID } from '../../utils/Utils';
import { getApiRoot, getNonGwApiRoot } from '../../constants/ParamountReactConstants';
import { ImportTypes } from '../../../types/import_export';
import { DefaultValue } from '../../../types/brand_settings';
import { triggerUpdateCounter } from '../catalogue/updateManager/update';
import {
  fetchItemsSummary,
  fetchBrandItemTags,
  fetchItemsByFilterId,
} from '../catalogue/catalogue/fetch';
import { ApplicationState } from '../../reducers';
import { getFileExtension } from '../../utils/ImportExportUtils';
import {
  EXCEL_FILE_EXTENSION,
  XML_FILE_EXTENSION,
  ASSET_FILE_EXTENSION,
} from '../../constants/ImportExportConstants';
import { brandAccDefaultValues } from '../../selectors/default_values/defaultValuesSelector';

export function fetchLatestImportLogs(userId: number) {
  const params = createURL([
    { name: 'limit', values: 50 },
    { name: 'user_id', values: userId },
  ]);

  return {
    type: '@IMPORTER/FETCH_LATEST_IMPORT_LOGS',
    payload: axios.get(`${getNonGwApiRoot()}/imports/logs${params}`),
  };
}

function refetchToolData() {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    const brandId = state.parent.brands.selectedBrandId;
    const filterId = state.catalogue.filter.filterGo?.id;
    const defaultValues = brandAccDefaultValues(state);
    const defLanguage = defaultValues.find((d: DefaultValue) => d.resource_table === 'languages');

    dispatch(triggerUpdateCounter());
    if (brandId) {
      dispatch(fetchItemsSummary(brandId));
      dispatch(fetchBrandItemTags(brandId));
    }
    if (filterId) {
      dispatch(fetchItemsByFilterId({ filterId, languageId: defLanguage?.value }));
    }
  };
}

export function fetchRunningImportLogs(logIds: (number | null)[]) {
  return async (dispatch: any) => {
    const params = createURL([{ name: 'import_log_ids', values: logIds.join(',') }]);

    const response = await axios.get(`${getNonGwApiRoot()}/imports/logs${params}`);

    const importCompleted = response.data.find((log: any) => log.completed_at);
    if (importCompleted) {
      dispatch(refetchToolData());
    }

    dispatch({
      type: '@IMPORTER/FETCH_RUNNING_IMPORT_LOGS_FULFILLED',
      payload: response,
    });
  };
}

const updateUploadProgress = (id: string, { loaded, total }: any) => {
  const progress = Number(((loaded / total) * 100).toFixed());
  return {
    type: '@IMPORTER/UPDATE_UPLOAD_PROGRESS',
    payload: {
      id,
      progress,
    },
  };
};

function createNewUpload(uuid: string, fileName: string, brandId: number, importType: ImportTypes) {
  return {
    type: '@IMPORTER/CREATE_NEW_UPLOAD',
    payload: {
      id: uuid,
      brandId,
      importType,
      fileName,
    },
  };
}

function uploadFinished(id: string, logId: number) {
  return {
    type: '@IMPORTER/UPLOAD_FINISHED',
    payload: {
      id,
      logId,
    },
  };
}

function uploadFailed(id: string) {
  return {
    type: '@IMPORTER/UPLOAD_FAILED',
    payload: {
      id,
    },
  };
}

export function fetchPresignedUrl(filename: string) {
  const params = createURL([{ name: 'filename', values: filename }]);

  return {
    type: 'FETCH_PRESIGNED_URL',
    payload: axios.get(`${getApiRoot()}/imports/presigned_url${params}`),
  };
}

export function closeImportNotificatoin(id: string) {
  return {
    type: '@IMPORTER/CLOSE_NOTIFICATION',
    payload: { id },
  };
}

export function openImportNotification(id: number) {
  return {
    type: '@IMPORTER/OPEN_NOTIFICATION',
    payload: { id },
  };
}

export function parentCloseImportNotification(id: string) {
  return {
    type: '@IMPORTER/PARENT_CLOSE_NOTIFICATION',
    payload: { id },
  };
}

export function parentOpenImportNotification(id: number) {
  return {
    type: '@IMPORTER/PARENT_OPEN_NOTIFICATION',
    payload: { id },
  };
}

function createExcelImport(file: any, importOptions: any, brandId: number) {
  return async (dispatch: Dispatch) => {
    const uuid = generateUUID();
    dispatch(createNewUpload(uuid, file.name, brandId, ImportTypes.EXCEL));

    const urlResponse: any = await dispatch(fetchPresignedUrl(file.name));
    const bucket = urlResponse.value.data;
    const url = bucket.url;
    const filename = bucket.filename;

    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      // Send upload progress for file upload.
      onUploadProgress: debounce(progress => {
        dispatch(updateUploadProgress(uuid, progress));
      }, 100),
    });

    const paramsArray: { name: string; values: number | string | undefined }[] = [
      { name: 'brand_id', values: brandId },
      { name: 'file_name', values: filename },
    ];

    try {
      const params = createURL(paramsArray);
      const response = await axios.post(`${getApiRoot()}/imports/excel_files${params}`, {
        import_options: importOptions,
      });
      const logId = response.data.import_log_id;
      dispatch(uploadFinished(uuid, logId));
    } catch (e) {
      dispatch(uploadFailed(uuid));
    }
  };
}

function createXMLImport(file: any, brandId: number, importType: ImportTypes, importOptions: any) {
  return async (dispatch: Dispatch) => {
    const uuid = generateUUID();
    dispatch(createNewUpload(uuid, file.name, brandId, importType));

    const urlResponse: any = await dispatch(fetchPresignedUrl(file.name));
    const bucket = urlResponse.value.data;
    const url = bucket.url;
    const filename = bucket.filename;

    await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
      // Send upload progress for file upload.
      onUploadProgress: debounce(progress => {
        dispatch(updateUploadProgress(uuid, progress));
      }, 100),
    });

    try {
      const params = createURL([
        { name: 'brand_id', values: brandId },
        { name: 'file_name', values: filename },
      ]);
      const response = await axios.post(`${getApiRoot()}/imports/xml_files${params}`, {
        import_options: importOptions,
      });
      const logId = response.data.import_log_id;
      dispatch(uploadFinished(uuid, logId));
    } catch (e) {
      dispatch(uploadFailed(uuid));
    }
  };
}

function createAssetImport(file: any, brandId: number, importOptions: any) {
  return async (dispatch: Dispatch) => {
    const uuid = generateUUID();
    dispatch(createNewUpload(uuid, file.name, brandId, ImportTypes.ASSET));

    const urlResponse: any = await dispatch(fetchPresignedUrl(file.name));
    const bucket = urlResponse.value.data;
    const url = bucket.url;
    const filename = bucket.filename;

    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      // Send upload progress for file upload.
      onUploadProgress: debounce(progress => {
        dispatch(updateUploadProgress(uuid, progress));
      }, 100),
    });

    const params = createURL([
      { name: 'brand_id', values: brandId },
      { name: 'zip_file_name', values: filename },
    ]);
    const response = await axios.post(`${getApiRoot()}/imports/digital_assets${params}`, {
      import_options: importOptions,
    });

    const logId = response.data.import_log_id;
    dispatch(uploadFinished(uuid, logId));
  };
}

export function importFile(file: File, fileType: ImportTypes, brandId: number, importOptions: any) {
  return (dispatch: any) => {
    const extension = getFileExtension(file.name);
    switch (fileType) {
      case ImportTypes.EXCEL: {
        if (extension !== EXCEL_FILE_EXTENSION) {
          message.error('invalid file');
          break;
        }
        dispatch(createExcelImport(file, importOptions, brandId));
        break;
      }
      case ImportTypes.ACES: {
        if (!XML_FILE_EXTENSION.includes(extension)) {
          message.error('invalid file');
          break;
        }
        dispatch(createXMLImport(file, brandId, ImportTypes.ACES, importOptions));
        break;
      }
      case ImportTypes.PIES: {
        if (!XML_FILE_EXTENSION.includes(extension)) {
          message.error('invalid file');
          break;
        }
        dispatch(createXMLImport(file, brandId, ImportTypes.PIES, importOptions));
        break;
      }
      case ImportTypes.ASSET: {
        if (extension !== ASSET_FILE_EXTENSION) {
          message.error('invalid file');
          break;
        }
        dispatch(createAssetImport(file, brandId, importOptions));
        break;
      }
    }
  };
}
