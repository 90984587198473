import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

export type AntSelectEntries = { id: string | number; name: string }[];

export type AntSelectProps = SelectProps<any> & {
  popupClassName?: string;
  elements?: AntSelectEntries;
  clearContainer?: boolean;
};

export default class AntSelect extends React.Component<AntSelectProps> {
  render() {
    const {
      className,
      popupClassName = '',
      elements = [],
      onChange,
      onSelect,
      onDeselect,
      onBlur,
      value,
      placeholder,
      size,
      disabled,
      showSearch,
      allowClear,
      bordered,
      suffixIcon,
      showArrow,
      clearContainer,
      style,
    } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onBlur={onBlur}
        placeholder={placeholder}
        size={size}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          // workaround for option.props has type never
          (option as any).props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        className={className}
        popupClassName={popupClassName}
        getPopupContainer={
          clearContainer
            ? undefined
            : trigger => (trigger && (trigger.parentNode as HTMLElement)) || document.body
        }
        bordered={bordered}
        suffixIcon={suffixIcon}
        showArrow={showArrow}
        style={style}
      >
        {elements.map(element => (
          <Select.Option key={element.id} value={element.id}>
            {element.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
