import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import {
  fetchValidatedAnalysisTypes,
  updateSelectedSegmentId,
  updateSelectedAlertTypeId,
  updateSelectedScorecardId,
} from '../../actions/settings/scorecards';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import {
  allResourcesAndAddValidatingInfo,
  filterResourcesAndAddValidatingInfo,
} from '../../selectors/settings/settingsSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import SettingsScorecard from '../../components/body/settings/SettingsScorecard';
import { getAllCustomFilters } from '../../selectors/catalogue/filterSelector';
import { fetchAllAccountFilters } from '../../actions/catalogue/filter/fetch';

const SettingsScorecardContainer: React.FC = () => {
  const dispatch = useDispatch();

  const {
    allAnalysisResources,
    brandId,
    brandCode,
    user,
    validatedAnalysisTypeIds,
    filterAnalysesResourcesById,
    filteredAnalysisResources,
    filters,
    accountFilters,
    fetchingFilters,
    filterId,
    fetchingAllAccountFilters
  } = useSelector((state: ApplicationState) => {
    return {
      allAnalysisResources: allResourcesAndAddValidatingInfo(state),
      brandId: state.parent.brands.selectedBrandId,
      brandCode: getSelectedBrandCode(state),
      user: state.user.user,
      validatedAnalysisTypeIds: state.settings.scorecards.validatedAnalysisTypeIds,
      filterId: state.settings.scorecards.filterId,
      filterAnalysesResourcesById: state.settings.scorecards.filterAnalysesResources,
      filteredAnalysisResources: filterResourcesAndAddValidatingInfo(state),
      filters: getAllCustomFilters(state),
      accountFilters: state.catalogue.filter.accountFilters,
      fetchingAllAccountFilters: state.catalogue.filter.fetchingAllAccountFilters,
      fetchingFilters: state.catalogue.filter.fetchingFilters,
    };
  });

  React.useEffect(() => {
    dispatch(fetchValidatedAnalysisTypes(brandId));
  }, [brandId, dispatch]);

  React.useEffect(() => {
    if (accountFilters.length === 0) dispatch(fetchAllAccountFilters());
  }, [accountFilters, dispatch]);

  React.useEffect(() => {
    intercomEvent('viewed-brand-settings', {
      location: 'settings-scorecard',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  const canManageScorecard = hasPermission(user, 'can_manage_scorecards');

  return (
    <SettingsScorecard
      canManageScorecard={canManageScorecard}
      brandId={brandId}
      updateSelectedSegmentId={id => dispatch(updateSelectedSegmentId(id))}
      updateSelectedAlertTypeId={id => dispatch(updateSelectedAlertTypeId(id))}
      updateSelectedScorecardId={id => dispatch(updateSelectedScorecardId(id))}
      analysisResources={allAnalysisResources}
      filterAnalysesResources={filterAnalysesResourcesById}
      validatedAnalysisTypeIds={validatedAnalysisTypeIds}
      selectedFilterId={filterId}
      filteredAnalysisResources={filteredAnalysisResources}
      filters={filters}
      fetchingFilters={fetchingFilters || fetchingAllAccountFilters}
      accountFilters={accountFilters}
    />
  );
};

export default SettingsScorecardContainer;
