import React from 'react';
import { useSelector } from 'react-redux';
import { Cascader } from 'antd';
import { ApplicationState } from '../../../reducers';
import translations from '../../../constants/CatalogueTranslations.json';
import { Order, OrderCondition, OrderOption } from '../../../../types/catalogue';
import useCatalogueQueryParams from '../../../hooks/useCatalogueQuery';
import { intercomEvent } from '../../../utils/IntercomUtils';

const CatalogueOrderSelect = () => {
  const conditionTranslations = translations.catalogueOrder.conditions as { [key: string]: string };
  const optionTranslations = translations.catalogueOrder.options as { [key: string]: string };

  const sendEvents = (order: Order) => {
    intercomEvent('changed-catalog-view', {
      location: 'sorting',
      sort_by: `${order.condition} ${order.option}`,
    });
  };

  const getOrderPreference = (orderCondition: OrderCondition) => {
    return orderCondition === OrderCondition.PART_NUMBER
      ? [OrderOption.ASCENDING, OrderOption.DESCENDING]
      : [OrderOption.DESCENDING, OrderOption.ASCENDING];
  };

  const getLabel = (orderCondition: OrderCondition, orderOption: OrderOption) => {
    if (orderCondition === OrderCondition.PART_NUMBER) {
      return orderOption === OrderOption.ASCENDING
        ? optionTranslations.aToZ
        : optionTranslations.zToA;
    }
    return orderOption === OrderOption.ASCENDING
      ? optionTranslations.oldToNew
      : optionTranslations.newToOld;
  };

  const orderCombinations = Object.values(OrderCondition).reduce(
    (acc: { [key: string]: any }[], condition) => [
      ...acc,
      {
        value: condition,
        label: conditionTranslations[condition],
        children: getOrderPreference(condition).map(option => ({
          value: option,
          label: getLabel(condition, option),
        })),
      },
    ],
    []
  );
  const { updateOrder } = useCatalogueQueryParams();
  const order = useSelector(
    (state: ApplicationState) => state.catalogue.catalogue.catalogueQueryParams.order
  );

  return (
    <Cascader
      placeholder={translations.catalogueOrder.placeholder}
      size="small"
      popupClassName="catalogue-bar__sort"
      options={orderCombinations}
      value={order ? [order.condition, order.option] : undefined}
      onChange={value => {
        const order = {
          condition: ((value && value[0]) || '') as OrderCondition,
          option: ((value && value[1]) || '') as OrderOption,
        };
        updateOrder(order);
        sendEvents(order);
      }}
    />
  );
};

export default CatalogueOrderSelect;
