import { DeleteOutlined, FileSearchOutlined, UpOutlined } from '@ant-design/icons';
import { Menu, Drawer, Button, Modal, Divider, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import queryString from 'query-string';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, WithRouterProps } from '../withRouter';
import { ApplicationState } from '../../reducers';
import { FilterType, FilterCustomReference, FilterTypeGo } from '../../../types/filter';
import { Resources } from '../../../types/resources';
import {
  getAllProductsFilter,
  getMarkedProductsFilter,
  getTempProductsFilter,
  getAllCustomFilters,
} from '../../selectors/catalogue/filterSelector';
import {
  mapFilterName,
  isCustomFilter,
  getFilterTags,
  isFilterEmpty,
} from '../../utils/FilterUtils';
import CatalogueBar from '../../components/body/catalogue/CatalogueBar';
import CatalogueListContainer from './CatalogueListContainer';
import CatalogueShopPreviewContainer from './CatalogueShopPreviewContainer';
import FilterContainer from '../header/FilterContainer';
import HelpCenter from '../HelpCenter';
import catalougeActions from '../../actions/catalogue/catalogue';
import filterActions from '../../actions/catalogue/filter';
import { isReceiver, isManufacturer } from '../../utils/UserUtils';
import { selectAllItems, setItemFetchRequired } from '../../actions/catalogue/catalogue/update';
import { AvailableReceiver } from '../../../types/receiver';
import { AvailableChannel } from '../../../types/channel';
import { Order, CatalogueQueryParams } from '../../../types/catalogue';
import { SubBrand } from '../../../types/brand';
import CatalogueReceiverFilterBrandSelect from '../../components/body/catalogue/CatalogueReceiverFilterBrandSelect';
import { fetchAPIToken } from '../../actions/distribution_apisettings';
import { APISettingsState } from '../../reducers/distribution_apiSettings/APISettingsReducer';
import { hasPermission, runningOnGoOnlyApi } from '../../utils/Permissions';
import { intercomEvent, sendFilterEvent } from '../../utils/IntercomUtils';
import { UserType } from '../../../types/user';
import { DefaultValue } from '../../../types/brand_settings';
import { fetchAndSelectFilter } from '../../actions/catalogue/filter/fetch';
import {
  resetFilter,
  updateTempFilterItems,
  updateTempFilterPartNumbers,
} from '../../actions/catalogue/filter/update';
import { brandAccDefaultValues } from '../../selectors/default_values/defaultValuesSelector';

const { confirm } = Modal;
const { SubMenu } = Menu;

type CatalogueContainerProps = {
  availableReceivers: AvailableReceiver[];
  availableChannels: AvailableChannel[];
  isReceiver: boolean;
  isManufacturer: boolean;
  location: any;
  brandId: number;
  subBrands: SubBrand[];
  receiverSubBrands: SubBrand[];
  brandItemTags: any[];
  filters: FilterType[];
  catalogueQueryParams: CatalogueQueryParams;
  selectedFilter: FilterType;
  selectedFilterGo: FilterTypeGo;
  allProductsFilter: FilterType;
  markedProductsFilter: FilterType;
  tempProductsFilter: FilterType;
  resources: Resources;
  brandCustomFields: any[];
  dispatch: any;
  catalogueExtended: boolean;
  catalougeIsResizing: boolean;
  customFilters: any[];
  changeCatalougeHeight: () => void;
  createParameterPending: boolean;
  apiSettings?: APISettingsState;
  user: UserType;
  defaultValues: DefaultValue[];
  itemFetchRequired: boolean;
  goOnly: boolean;
} & WithRouterProps &
  WithTranslation;

type CatalogueContainerState = {
  showFilterDrawer: boolean;
  fetchItemsOnParamCreate: boolean;
};

class CatalogueContainer extends React.Component<CatalogueContainerProps, CatalogueContainerState> {
  state = {
    showFilterDrawer: false,
    fetchItemsOnParamCreate: false,
  };

  async componentDidMount() {
    const {
      location,
      allProductsFilter,
      tempProductsFilter,
      selectedFilterGo,
      isReceiver,
      user,
      itemFetchRequired,
      goOnly,
    } = this.props;

    const params = queryString.parse(location.search, { arrayFormat: 'comma' });

    // handle part number select by temp filter
    if (Array.isArray(params.partNumbers)) {
      if (!selectedFilterGo || selectedFilterGo?.id !== tempProductsFilter.id)
        // goOnly ? await this.props.dispatch(fetchFilterGo(tempProductsFilter.id)) :
        await this.props.dispatch(fetchAndSelectFilter(tempProductsFilter.id));
      params.filterId = tempProductsFilter.id.toString();

      // if (goOnly) {
      //   const response = await this.props.dispatch(resetFilter(tempProductsFilter?.id));
      //   const filter = response.value.data.filter;
      //   // updateTempFilterPartNumbers
      //   await this.props.dispatch(updateFilterGo({ ...filter, part_numbers: params.partNumbers }));
      // } else {
      await this.props.dispatch(resetFilter(tempProductsFilter?.id));
      await this.props.dispatch(updateTempFilterItems(tempProductsFilter?.id));
      await this.props.dispatch(
        updateTempFilterPartNumbers(tempProductsFilter?.id, params.brandId, params.partNumbers)
      );
      // }

      await this.fetchItemsByFilterId({ filterId: tempProductsFilter.id });

      this.props.dispatch(selectAllItems());
    }

    // If a filter is already selected, don't do anything. Apart of temp filter.
    // This is the case when navigating back and forth between other areas and the catalogue. (Manufacturer)
    // for receiver we always want to check the params
    const filterId = Number(params.filterId) || allProductsFilter.id;
    if (filterId === tempProductsFilter.id && !Array.isArray(params.partNumbers)) {
      this.updateSearchResult({ filterId });
      if (itemFetchRequired) this.props.dispatch(setItemFetchRequired(false));
    } else if (isReceiver) {
      this.fetchItemsByFilterId({ filterId: selectedFilterGo.id });
    } else if (itemFetchRequired) {
      this.fetchItemsByFilterId({ filterId: selectedFilterGo.id });
      this.props.dispatch(setItemFetchRequired(false));
    }
    if (hasPermission(user, 'has_api_access')) this.props.dispatch(fetchAPIToken({}));

    if (isReceiver) intercomEvent('viewed-all-products', { location: 'all-products' });
  }

  componentDidUpdate() {
    const { fetchItemsOnParamCreate } = this.state;
    const { createParameterPending, selectedFilter, goOnly } = this.props;
    if (!goOnly && fetchItemsOnParamCreate && !createParameterPending) {
      this.setState({ fetchItemsOnParamCreate: false });
      // this.props
      //   .dispatch(updateFilterGo(convertToGoFilterStructure(selectedFilter)))
      //   .then(() => this.fetchItemsByFilterId({ filterId: selectedFilter.id }));
      this.fetchItemsByFilterId({ filterId: selectedFilter.id });
    }
  }

  fetchFilter = (key: number) => {
    if (!this.props.goOnly) return this.props.dispatch(filterActions.fetchAndSelectFilter(key));
    // return this.props.dispatch(fetchFilterGo(key));
  };

  fetchItemsByFilterId = ({
    filterId,
    page = 1,
    filterBrandIds = this.props.catalogueQueryParams.filterBrandIds,
    selectedReceiverId = this.props.catalogueQueryParams.selectedReceiverId,
    selectedChannelId = this.props.catalogueQueryParams.selectedChannelId,
    order = this.props.catalogueQueryParams.order,
  }: {
    filterId: number;
    page?: number;
    filterBrandIds?: number[];
    selectedReceiverId?: number | null;
    selectedChannelId?: number | null;
    order?: Order | null;
  }) => {
    const { defaultValues } = this.props;
    const defLanguageId = defaultValues.find(d => d.resource_table === 'languages')?.value;

    return this.props.dispatch(
      catalougeActions.fetchItemsByFilterId({
        filterId,
        page,
        filterBrandIds,
        selectedReceiverId,
        selectedChannelId,
        order,
        languageId: defLanguageId,
      })
    );
  };

  updateSearchResult = ({
    filterId,
    page = 1,
    filterBrandIds = this.props.catalogueQueryParams.filterBrandIds,
  }: {
    filterId: number;
    page?: number;
    filterBrandIds?: number[];
  }) => {
    this.fetchFilter(filterId);
    return this.fetchItemsByFilterId({ filterId, page, filterBrandIds });
  };

  updateSearchResultAndUrl = ({
    filterId,
  }: {
    filterId: number;
    page?: number;
    filterBrandIds?: number[];
  }) => {
    if (filterId === this.props.allProductsFilter.id) {
      // brandId is for manufacturer
      this.props.navigate(
        this.props.brandId
          ? `?brandId=${this.props.brandId}`
          : '/available-brands/all-products/item'
      );
    } else {
      this.props.navigate(
        this.props.brandId
          ? `?brandId=${this.props.brandId}&filterId=${filterId}`
          : `?filterId=${filterId}`
      );
    }
    this.updateSearchResult({ filterId }).catch((error: any) => {
      // We don't count canceled requests (axios cancelToken) as an error
      // eslint-disable-next-line
      if (error && error.__CANCEL__) return;
      message.error(this.props.t('catalogoue:changeFilterFailed', { filterId, error }));
      this.props.navigate(-1);
    });
  };

  handleItemListScroll = (index: any) => {
    const { selectedFilterGo } = this.props;
    const nextPage = Math.ceil(index.stopIndex / 100);
    return this.fetchItemsByFilterId({ filterId: selectedFilterGo.id, page: nextPage });
  };

  hideTempFilter = () => {
    const { t, allProductsFilter, selectedFilterGo, tempProductsFilter, goOnly } = this.props;

    confirm({
      title: t('filter:confirm_delete_temp_filter'),
      okText: t('common:delete'),
      okButtonProps: { danger: true },
      onOk: () => {
        if (!goOnly)
          this.props.dispatch(
            filterActions.updateTempFilterItems(this.props.tempProductsFilter.id, [])
          );
        if (selectedFilterGo.id === tempProductsFilter.id) {
          this.handleProductMenuSelect({ filterId: allProductsFilter.id });
        }
      },
    });
  };

  resetFilter = (filterId: number) => this.props.dispatch(filterActions.resetFilter(filterId));

  handleResetFilterOnMenuChange = (key: number) => {
    const filter = this.props.filters.find(({ id }) => id === key);
    // Reset changes in standard filters (all, marked, temp) which are on purpose not persistet.
    if (filter && !isCustomFilter(filter)) {
      this.resetFilter(key);
    }
  };

  handleProductMenuSelect = ({ filterId }: { filterId: number }) => {
    const { selectedFilterGo } = this.props;
    this.handleResetFilterOnMenuChange(selectedFilterGo.id);
    this.updateSearchResultAndUrl({ filterId });

    intercomEvent('viewed-all-products', {
      location: 'filter-name',
      filter_name: this.props.filters.find(f => f.id === filterId)?.name || '',
    });
  };

  handleFilterByKeywords = async (keywords: string) => {
    const { selectedFilter, selectedFilterGo, goOnly } = this.props;

    if (!goOnly)
      await this.props.dispatch(filterActions.updateFilter(selectedFilter.id, { keywords }));
    // await this.props.dispatch(updateFilterGo({ ...selectedFilterGo, keywords }));
    this.updateSearchResult({ filterId: selectedFilterGo.id });
  };

  handleOpenFilter = () => {
    const { createParameterPending, selectedFilter, selectedFilterGo, goOnly } = this.props;

    this.setState(prevState => {
      const showFilterDrawer = !prevState.showFilterDrawer;
      let fetchItemsOnParamCreate = prevState.fetchItemsOnParamCreate;
      if (!showFilterDrawer) {
        if (!createParameterPending) {
          // if (!goOnly)
          //   this.props
          //     .dispatch(updateFilterGo(convertToGoFilterStructure(selectedFilter)))
          //     .then(() => this.fetchItemsByFilterId({ filterId: selectedFilterGo.id }));
          // else this.fetchItemsByFilterId({ filterId: selectedFilterGo.id });
          this.fetchItemsByFilterId({ filterId: selectedFilterGo.id });
        } else fetchItemsOnParamCreate = true;
      }
      return { showFilterDrawer, fetchItemsOnParamCreate };
    });

    sendFilterEvent(this.props.selectedFilterGo);
  };

  handleSaveNewFilter = async (filterName: string) => {
    const { selectedFilterGo, allProductsFilter } = this.props;
    return this.props
      .dispatch(filterActions.copyFilter(filterName, selectedFilterGo.id))
      .then(() => {
        const allFilterId = allProductsFilter.id;
        this.resetFilter(allFilterId);
        return Promise.resolve();
      })
      .catch((e: any) => Promise.reject(e));
  };

  handleAddNewProduct = (partNumber: string, selectedSubrandId: number) => {
    return this.props.dispatch(
      catalougeActions.createItem({
        partNumber,
        brandId: this.props.brandId,
        subBrandId: selectedSubrandId,
        currentFilterId: this.props.selectedFilterGo.id,
        selectedChannelId: this.props.catalogueQueryParams.selectedChannelId,
        selectedReceiverId: this.props.catalogueQueryParams.selectedReceiverId,
        languageId: this.props.defaultValues.find(d => d.resource_table === 'languages')?.value,
        order: this.props.catalogueQueryParams.order,
      })
    );
  };

  getFilterParameterId = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number;
    filterCustomReferenceId?: number;
  }) => {
    const { selectedFilter } = this.props;
    let filterParameterId;
    // @ts-ignore
    Object.values(selectedFilter[sectionKey]).forEach((block: any) => {
      Object.keys(block.parameters || []).forEach(paramId => {
        const parameter = block.parameters[paramId];

        if (sectionKey === 'filter_category') {
          if (level === parameter.level) filterParameterId = paramId;
        } else if (sectionKey === 'filter_application') {
          if (resource === parameter.resource) filterParameterId = paramId;
        } else if (sectionKey === 'filter_analysis') {
          if (referenceId === parameter.reference_id) filterParameterId = paramId;
        } else if (sectionKey === 'filter_custom') {
          if (
            filterCustomReferenceId === parameter.filter_custom_reference_id &&
            referenceId === parameter.reference_id
          )
            filterParameterId = paramId;
        }
      });
    });

    return filterParameterId;
  };

  handleDeleteFilterParameter = async ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }: {
    sectionKey: string;
    resource?: string;
    level?: number;
    referenceId?: number;
    filterCustomReferenceId?: number;
  }) => {
    const { selectedFilter, selectedFilterGo, goOnly } = this.props;

    // if (goOnly) {
    //   const updatedFilter = removeFilterParameter({
    //     sectionKey,
    //     resource,
    //     level,
    //     referenceId,
    //     filterCustomReferenceId,
    //     filterGo: selectedFilterGo,
    //   });

    //   await this.props.dispatch(updateFilterGo(updatedFilter));
    //   return this.updateSearchResult({ filterId: selectedFilterGo.id });
    // }

    const paramId = this.getFilterParameterId({
      sectionKey,
      resource,
      level,
      referenceId,
      filterCustomReferenceId,
    });

    let fResponse;
    if (sectionKey === 'filter_category') {
      fResponse = await this.props.dispatch(filterActions.deleteFilterCategoryParameter(paramId));
    } else if (sectionKey === 'filter_application') {
      fResponse = await this.props.dispatch(
        filterActions.deleteFilterApplicationParameter(paramId)
      );
    } else if (sectionKey === 'filter_analysis') {
      fResponse = await this.props.dispatch(filterActions.deleteFilterAnalysisParameter(paramId));
    } else if (sectionKey === 'filter_custom') {
      fResponse = await this.props.dispatch(filterActions.deleteFilterCustomParameter(paramId));
    }

    if (fResponse) {
      // await this.props.dispatch(
      //   updateFilterGo(convertToGoFilterStructure(fResponse.value.data.filter))
      // );
      this.updateSearchResult({ filterId: selectedFilter.id });
    }
  };

  getTagParameterId = (customFilter: any) => {
    let parameterId;
    Object.keys(customFilter.parameters).forEach(id => {
      const parameter = customFilter.parameters[id];
      if (parameter.filter_custom_reference_id === 5) parameterId = id;
    });
    return parameterId;
  };

  createTagParameter = (
    customFilterId: string,
    customRefId: number,
    optionId: number,
    value: string
  ) => {
    // create new filter parameter including value
    return this.props.dispatch(
      filterActions.createFilterCustomParameter({
        filterId: customFilterId,
        optionId,
        value,
        customRefId,
        refId: null,
      })
    );
    // .then((res: { value: { data: { filter: FilterType } } }) =>
    //   this.props.dispatch(updateFilterGo(convertToGoFilterStructure(res.value.data.filter)))
    // );
  };

  handleAddTag = (tagName: string) => {
    const { selectedFilter, brandItemTags } = this.props;
    const customRefId = FilterCustomReference.TAGS;
    const optionId = 1; // is one of
    const tag = brandItemTags.find(tag => tag.name === tagName);

    // if (goOnly) {
    //   const updatedFilter = addFilterTag(customRefId, tag, optionId, selectedFilterGo);

    //   this.props
    //     .dispatch(updateFilterGo(updatedFilter))
    //     .then(() => this.fetchItemsByFilterId({ filterId: selectedFilterGo.id }));
    // } else
    if (selectedFilter.filter_custom) {
      const customFilterId = Object.keys(selectedFilter.filter_custom)[0];
      const parameterId = this.getTagParameterId(selectedFilter.filter_custom[customFilterId]);

      if (parameterId) {
        this.props
          .dispatch(filterActions.addFilterCustomParameterValue(parameterId, tag.id))
          .then((res: { value: { data: { filter: FilterType } } }) => {
            // this.props
            //   .dispatch(updateFilterGo(convertToGoFilterStructure(res.value.data.filter)))
            //   .then(() => this.fetchItemsByFilterId({ filterId: selectedFilter.id }));
            this.fetchItemsByFilterId({ filterId: selectedFilter.id });
          });
      } else {
        this.createTagParameter(customFilterId, customRefId, optionId, tag.id).then(() =>
          this.fetchItemsByFilterId({ filterId: this.props.selectedFilter.id })
        );
      }
    } else {
      this.props
        .dispatch(filterActions.createCustomFilter(selectedFilter.id))
        .then((result: any) => {
          const filter = result.value.data.filter;
          const customFilterId = Object.keys(filter.filter_custom)[0];
          this.createTagParameter(customFilterId, customRefId, optionId, tag.id).then(() =>
            this.fetchItemsByFilterId({ filterId: this.props.selectedFilter.id })
          );
        });
    }

    intercomEvent('filter', { location: 'all-products', filter_by: 'tags' });
  };

  handleRemoveTag = async (tagId: number) => {
    const { selectedFilter, selectedFilterGo, goOnly } = this.props;

    // if (goOnly) {
    //   const updatedFilter = removeFilterTag(FilterCustomReference.TAGS, tagId, selectedFilterGo);
    //   await this.props.dispatch(updateFilterGo(updatedFilter));
    // } else {
    const customFilterId = Object.keys(selectedFilter.filter_custom)[0];
    const customFilter = selectedFilter.filter_custom[customFilterId];
    const paramId = Object.keys(customFilter.parameters).find(
      id => customFilter.parameters[id].filter_custom_reference_id === FilterCustomReference.TAGS
    ) as string;
    const tagParameter = customFilter.parameters[paramId];
    const valueId = Object.keys(tagParameter.values).find(
      id => Number(tagParameter.values[id].value) === tagId
    );

    let fResponse;
    if (Object.keys(tagParameter.values).length > 1) {
      fResponse = await this.props.dispatch(
        filterActions.removeFilterCustomParameterValues(paramId, valueId)
      );
    } else {
      fResponse = await this.props.dispatch(filterActions.deleteFilterCustomParameter(paramId));
    }

    // await this.props.dispatch(
    //   updateFilterGo(convertToGoFilterStructure(fResponse.value.data.filter))
    // );
    // }

    this.updateSearchResult({ filterId: selectedFilterGo.id });

    intercomEvent('filter', { location: 'all-products', filter_by: 'tags' });
  };

  handleFilterDelete = (filterId: number) => {
    const { allProductsFilter, selectedFilterGo, t } = this.props;
    const action = () => {
      if (filterId === selectedFilterGo.id) {
        this.updateSearchResult({ filterId: allProductsFilter.id });
      }
      return this.props.dispatch(filterActions.deleteFilter(filterId));
    };
    const filter = this.props.filters.find(filter => filter.id === filterId);
    confirm({
      title: t('filter:confirm_delete_filter', {
        name: filter ? filter.name : t('common:unknown'),
      }),
      okText: t('common:delete'),
      okButtonProps: { danger: true },
      onOk() {
        action();
      },
    });
  };

  handleChangeCatalougeHeight = () => {
    this.props.changeCatalougeHeight();
    if (this.props.isReceiver)
      intercomEvent('viewed-all-products', { location: 'viewed-catalogue' });

    intercomEvent('changed-catalog-view', { location: 'expanded catalog' });
  };

  isApiActive = () => {
    const { apiSettings } = this.props;

    return !!apiSettings?.token?.token;
  };

  renderFilterMenu = (filterName: string, filterId: number) => {
    const { t } = this.props;
    if (this.isApiActive())
      return <Tooltip title={`${t('common:id')}: ${filterId}`}>{filterName}</Tooltip>;
    return filterName;
  };

  render() {
    const {
      allProductsFilter,
      markedProductsFilter,
      tempProductsFilter,
      selectedFilterGo,
      catalogueQueryParams,
      isReceiver,
      goOnly,
      t,
    } = this.props;
    if (!selectedFilterGo) {
      return (
        <div className="catalogue-container">
          <div className="catalogue-bar" />
          <div className="catalogue-menu-list-container">
            <div className="catalogue-left-menu">
              <div className="catalogue-shop-preview" />
              <div className="catalogue-menu-container" />
            </div>
            <div className="catalogue-list-container-placeholder" />
          </div>
        </div>
      );
    }

    return (
      <div className="catalogue-container">
        <CatalogueBar
          availableReceivers={this.props.availableReceivers}
          availableChannels={this.props.availableChannels}
          isManufacturer={this.props.isManufacturer}
          selectedFilterGo={this.props.selectedFilterGo}
          filters={this.props.filters}
          goOnly={goOnly}
          brandCustomFields={this.props.brandCustomFields}
          disableKeywordInput={isReceiver && catalogueQueryParams.filterBrandIds.length === 0}
          fetchFilter={this.updateSearchResultAndUrl}
          brandItemTags={this.props.brandItemTags}
          resources={this.props.resources}
          subBrands={isReceiver ? this.props.receiverSubBrands : this.props.subBrands}
          handleAddNewProduct={this.handleAddNewProduct}
          handleOpenFilter={this.handleOpenFilter}
          handleSaveNewFilter={this.handleSaveNewFilter}
          handleFilterByKeywords={this.handleFilterByKeywords}
          handleDeleteFilterParameter={this.handleDeleteFilterParameter}
          handleAddTag={this.handleAddTag}
          handleRemoveTag={this.handleRemoveTag}
        />
        <div className="catalogue-menu-list-container">
          <div className="catalogue-left-menu">
            <CatalogueShopPreviewContainer />
            {this.props.isReceiver && (
              <React.Fragment>
                <div className="receiver-catalogue__filter-brand">
                  <div className="receiver-catalogue__filter-brand__label">
                    {t('filter:onlySelectedBrands')}
                  </div>
                  <CatalogueReceiverFilterBrandSelect />
                </div>
                <Divider className="receiver-catalogue__sidebar-divider" />
              </React.Fragment>
            )}
            {this.props.catalogueExtended && (
              <div className="catalogue-menu-container">
                <Menu
                  mode="inline"
                  onSelect={({ key }) => this.handleProductMenuSelect({ filterId: Number(key) })}
                  selectedKeys={[this.props.selectedFilterGo.id.toString()]}
                  defaultOpenKeys={['filter']}
                >
                  {tempProductsFilter &&
                    (tempProductsFilter.item_ids || !isFilterEmpty(selectedFilterGo)) && (
                      <Menu.Item key={tempProductsFilter.id && tempProductsFilter.id.toString()}>
                        <div className="filter-custom-menu">
                          {this.renderFilterMenu(t('filter:temp_filter'), tempProductsFilter.id)}
                          <div>
                            <HelpCenter id="temp_filter" />
                            <DeleteOutlined
                              className="icon-delete-filter"
                              onClick={e => {
                                e.stopPropagation();
                                this.hideTempFilter();
                              }}
                            />
                          </div>
                        </div>
                      </Menu.Item>
                    )}
                  <Menu.Item key={allProductsFilter.id && allProductsFilter.id.toString()}>
                    {this.renderFilterMenu(t('filter:all_products'), allProductsFilter.id)}
                  </Menu.Item>
                  {this.props.isManufacturer && (
                    <Menu.Item key={markedProductsFilter.id && markedProductsFilter.id.toString()}>
                      {this.renderFilterMenu(t('filter:marked_products'), markedProductsFilter.id)}
                    </Menu.Item>
                  )}
                  <SubMenu
                    key="filter"
                    title={t('filter:custom_filters')}
                    disabled={!this.props.customFilters.length}
                  >
                    {this.props.customFilters.map(filter => (
                      <Menu.Item key={filter.id.toString()}>
                        <div className="filter-custom-menu__sub">
                          <span className="filter-custom-menu__name" title={filter.name}>
                            {this.renderFilterMenu(filter.name, filter.id)}
                          </span>
                          <DeleteOutlined
                            className="icon-delete-filter"
                            onClick={e => {
                              e.stopPropagation();
                              this.handleFilterDelete(filter.id);
                            }}
                          />
                        </div>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                </Menu>
              </div>
            )}
          </div>
          <CatalogueListContainer
            handleItemListScroll={this.handleItemListScroll}
            catalogueExtended={this.props.catalogueExtended}
            catalougeIsResizing={this.props.catalougeIsResizing}
            updateSearchResult={this.handleProductMenuSelect}
            changeCatalougeHeight={this.handleChangeCatalougeHeight}
            handleAddTagFilter={this.handleAddTag}
            handleRemoveTagFilter={this.handleRemoveTag}
            selectedFilterTagIds={
              !selectedFilterGo?.filter_custom ? [] : getFilterTags(selectedFilterGo)
            }
          />
        </div>
        {this.props.catalogueExtended && (
          <div className="catalogoue-extended-bottom">
            <Button onClick={this.handleChangeCatalougeHeight} type="primary" size="small">
              <FileSearchOutlined />
              {this.props.isManufacturer && t('catalogue:editSelectedProducts')}
              {this.props.isReceiver && t('catalogue:openDetailedProductPreview')}
              <UpOutlined />
            </Button>
          </div>
        )}

        <Drawer
          open={this.state.showFilterDrawer}
          onClose={this.handleOpenFilter}
          title={t('catalogue:filterName', {
            filterName: mapFilterName(this.props.selectedFilterGo),
          })}
          width="80vw"
        >
          {this.state.showFilterDrawer && <FilterContainer />}
          <div className="catalogoue-extended-bottom">
            <Button onClick={this.handleOpenFilter} type="primary" size="small">
              {t('catalogue:showResults')}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const allProductsFilter = getAllProductsFilter(state);
  const markedProductsFilter = getMarkedProductsFilter(state);
  const tempProductsFilter = getTempProductsFilter(state);
  const customFilters = getAllCustomFilters(state);
  return {
    brandId: state.parent.brands.selectedBrandId,
    subBrands: state.brand.currentBrand.subBrands,
    brandItemTags: state.catalogue.catalogue.brandItemTags,
    filters: state.catalogue.filter.filters,
    catalogueQueryParams: state.catalogue.catalogue.catalogueQueryParams,
    itemFetchRequired: state.catalogue.catalogue.itemFetchRequired,
    selectedFilter: state.catalogue.filter.filter,
    selectedFilterGo: state.catalogue.filter.filterGo,
    createParameterPending: state.catalogue.filter.createParameterPending,
    allProductsFilter,
    markedProductsFilter,
    tempProductsFilter,
    customFilters,
    resources: state.resources.data,
    brandCustomFields: state.brand.customFields.customFields,
    availableChannels: state.brand.currentBrand.availableChannels,
    availableReceivers: state.brand.currentBrand.availableReceivers,
    receiverSubBrands: state.receiverDataStream.subBrands,
    isReceiver: state.user.user && isReceiver(state.user.user),
    isManufacturer: state.user.user && isManufacturer(state.user.user),
    goOnly: state.user.user && runningOnGoOnlyApi(state.user.user),
    apiSettings: state.distribution.apiSettings,
    user: state.user.user,
    defaultValues: brandAccDefaultValues(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTranslation()(CatalogueContainer as any) as any)
) as any;
