import { Reducer } from 'redux';
import { omit as _omit } from 'lodash';
import { FluxStandardAction } from 'redux-promise-middleware';
import { AccountFilter, FilterType, FilterTypeGo } from '../../../types/filter';
import { convertToGoFilterStructure } from '../../utils/FilterUtils';

export type FilterState = {
  readonly filters: FilterType[];
  readonly filter: FilterType;
  readonly filterGo?: FilterTypeGo;
  readonly accountFilters: AccountFilter[];
  readonly fetchingAllAccountFilters: boolean;
  readonly fetchingFilters: boolean;
  readonly fetchingCategory: boolean;
  readonly fetchingSubCategory: boolean;
  readonly fetchingPartType: boolean;
  readonly fetchingYears: boolean;
  readonly categories: Category[];
  readonly subCategories: Category[];
  readonly partTypes: Category[];
  readonly makes: any[]; // TODO: add type
  readonly models: any[]; // TODO: add type
  readonly years: any[]; // TODO: add type
  readonly submodels: any[]; // TODO: add type
  readonly regions: any[]; // TODO: add type
  readonly analysesAlertItemCount: AnalysesAlertItemCount[] | undefined;
  readonly fetchingAnalysesAlertItemCount: boolean;
  readonly rankedApplications: any;
  readonly createParameterPending: boolean;
};

type Category = {
  id: number;
  level1_name: string;
  is_used: number;
  parent_id: number;
  description_id: number;
  description: string;
  mapped: number;
  expiration_date?: null;
  used_by_brand: number;
  status_code?: number;
  level2_name?: string;
  level3_name?: string;
};

type AnalysesAlertItemCount = {
  type_id: number;
  item_count: number;
};

const initialState: FilterState = {
  filters: [],
  filter: {} as FilterType,
  filterGo: undefined,
  accountFilters: [],
  fetchingAllAccountFilters: false,
  fetchingFilters: false,
  fetchingCategory: false,
  fetchingSubCategory: false,
  fetchingPartType: false,
  fetchingYears: false,
  categories: [],
  subCategories: [],
  partTypes: [],
  rankedApplications: undefined,
  makes: [],
  models: [],
  years: [],
  submodels: [],
  regions: [],
  analysesAlertItemCount: undefined,
  fetchingAnalysesAlertItemCount: false,
  createParameterPending: false,
};

const reducer: Reducer<FilterState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'COPY_FILTER_FULFILLED': {
      const newFilter = action.payload.data;
      return {
        ...state,
        filters: [newFilter, ...state.filters],
        filter: newFilter,
      };
    }
    case 'DELETE_FILTER_PENDING': {
      const filters = state.filters.filter(filter => filter.id !== action.meta.filterId);
      return { ...state, filters };
    }
    case 'FETCH_FILTERS_PENDING': {
      return { ...state, filters: [], fetchingFilters: true };
    }
    case 'FETCH_FILTERS_REJECTED': {
      return { ...state, fetchingFilters: false };
    }
    case 'FETCH_FILTERS_FULFILLED': {
      const filters = action.payload.data;
      return { ...state, filters, fetchingFilters: false };
    }
    case 'FETCH_ALL_ACCOUNT_FILTERS_PENDING': {
      const accFilters = state.accountFilters;
      return { ...state, accountFilters: accFilters, fetchingAllAccountFilters: true };
    }
    case 'FETCH_ALL_ACCOUNT_FILTERS_FULFILLED': {
      const accFilters = action.payload.data.filters;
      return { ...state, accountFilters: accFilters, fetchingAllAccountFilters: false };
    }
    case 'SET_EMPTY_FILTER': {
      return { ...state, filter: {} };
    }
    case 'FETCH_FILTER_FULFILLED': {
      const filter = action.payload.data;
      const filterGo = convertToGoFilterStructure(action.payload.data);
      return {
        ...state,
        filter: state.filters.find(f => f.id === filter.id) ? filter : state.filter,
        filterGo: state.filters.find(f => f.id === filterGo.id) ? filterGo : state.filterGo,
      };
    }
    case 'FETCH_FILTER_GO_FULFILLED': {
      const filterGo = action.payload.data;
      return {
        ...state,
        filterGo: state.filters.find(f => f.id === filterGo.id) ? filterGo : state.filterGo,
      };
    }
    case 'SELECT_FILTER': {
      const filter = state.filters.find(({ id }) => id === action.payload);
      return { ...state, filter: filter || state.filter };
    }
    case 'CREATE_APPLICATION_FILTER_FULFILLED': {
      const newFilter = action.payload.data.filter;
      const filters = state.filters.map(filter =>
        filter.id === newFilter.id ? newFilter : filter
      );
      return {
        ...state,
        filter: newFilter,
        filterGo: {
          ...state.filterGo!,
          filter_application: [{ and_condition: true, parameters: [] }],
        },
        filters,
      };
    }
    case 'CREATE_CATEGORY_FILTER_FULFILLED': {
      const newFilter = action.payload.data.filter;
      const filters = state.filters.map(filter =>
        filter.id === newFilter.id ? newFilter : filter
      );
      return {
        ...state,
        filter: newFilter,
        filterGo: {
          ...state.filterGo!,
          filter_category: [{ and_condition: true, parameters: [] }],
        },
        filters,
      };
    }
    case 'CREATE_ANALYSIS_FILTER_FULFILLED': {
      const newFilter = action.payload.data.filter;
      const filters = state.filters.map(filter =>
        filter.id === newFilter.id ? newFilter : filter
      );
      return {
        ...state,
        filter: newFilter,
        filterGo: {
          ...state.filterGo!,
          filter_analysis: [{ and_condition: true, parameters: [] }],
        },
        filters,
      };
    }
    case 'CREATE_CUSTOM_FILTER_FULFILLED': {
      const newFilter = action.payload.data.filter;
      const filters = state.filters.map(filter =>
        filter.id === newFilter.id ? newFilter : filter
      );
      return {
        ...state,
        filter: newFilter,
        filterGo: {
          ...state.filterGo!,
          filter_custom: [{ and_condition: true, parameters: [] }],
        },
        filters,
      };
    }
    case 'UPDATE_FILTER_PENDING': {
      const filters = state.filters.map(filter =>
        filter.id === action.meta.filterId && action.meta.nameChanged
          ? { ...filter, name: action.meta.name }
          : filter
      );
      return { ...state, filters };
    }
    case 'UPDATE_FILTER_FULFILLED': {
      const newFilter = action.payload.data.filter;
      const newFilterGo = convertToGoFilterStructure(action.payload.data.filter);
      return {
        ...state,
        filter: newFilter.id === state.filter.id ? newFilter : state.filter,
        filterGo: newFilterGo.id === state.filterGo?.id ? newFilterGo : state.filterGo,
      };
    }
    case 'UPDATE_FILTER_GO_PENDING': {
      const newFilter = action.meta.filter;
      return {
        ...state,
        filterGo: newFilter.id === state.filterGo?.id ? newFilter : state.filterGo,
      };
    }
    case 'UPDATE_FILTER_GO_FULFILLED': {
      const newFilter = action.payload.data;
      return {
        ...state,
        filterGo: newFilter.id === state.filterGo?.id ? newFilter : state.filterGo,
      };
    }
    case 'DELETE_APPLICATION_FILTER_PENDING': {
      const filter = JSON.parse(JSON.stringify(state.filter));
      delete filter.filter_application;
      filter.delete = true;
      return {
        ...state,
        filter,
        filterGo: { ...state.filterGo!, filter_application: null },
        fetchingItems: true,
      };
    }
    case 'DELETE_APPLICATION_FILTER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
      };
    }
    case 'DELETE_CATEGORY_FILTER_PENDING': {
      const filter = JSON.parse(JSON.stringify(state.filter));
      delete filter.filter_category;
      filter.delete = true;
      return {
        ...state,
        filter,
        filterGo: { ...state.filterGo!, filter_category: null },
        fetchingItems: true,
      };
    }
    case 'DELETE_CATEGORY_FILTER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
      };
    }
    case 'DELETE_ANALYSIS_FILTER_PENDING': {
      const filter = JSON.parse(JSON.stringify(state.filter));
      delete filter.filter_analysis;
      filter.delete = true;
      return {
        ...state,
        filter,
        filterGo: { ...state.filterGo!, filter_analysis: null },
      };
    }
    case 'DELETE_ANALYSIS_FILTER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
      };
    }
    case 'DELETE_CUSTOM_FILTER_PENDING': {
      const filter = JSON.parse(JSON.stringify(state.filter));
      delete filter.filter_custom;
      filter.delete = true;
      return {
        ...state,
        filter,
        filterGo: { ...state.filterGo!, filter_custom: null },
      };
    }
    case 'DELETE_CUSTOM_FILTER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
      };
    }
    case 'DELETE_CATEGORY_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;
      const filterGo = convertToGoFilterStructure(action.payload.data.filter);
      return {
        ...state,
        filter,
        filterGo,
      };
    }
    case 'DELETE_APPLICATION_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;
      const filterGo = convertToGoFilterStructure(action.payload.data.filter);
      return {
        ...state,
        filter,
        filterGo,
      };
    }
    case 'DELETE_ANALYSIS_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;
      const filterGo = convertToGoFilterStructure(action.payload.data.filter);
      return {
        ...state,
        filter,
        filterGo,
      };
    }
    case 'DELETE_CUSTOM_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;
      const filterGo = convertToGoFilterStructure(action.payload.data.filter);
      return {
        ...state,
        filter,
        filterGo,
      };
    }
    case 'REMOVE_APPLICATION_FILTER_VALUE_FULFILLED': {
      const filter = action.payload.data.filter;
      return {
        ...state,
        filter,
      };
    }

    case 'REMOVE_CATEGORY_FILTER_VALUE_FULFILLED': {
      const filter = action.payload.data.filter;

      return { ...state, filter };
    }
    case 'REMOVE_ANALYSIS_FILTER_VALUE_FULFILLED': {
      const filter = action.payload.data.filter;

      return { ...state, filter };
    }
    case 'REMOVE_CUSTOM_FILTER_VALUE_PENDING': {
      const { parameterId, valueId } = action.meta;
      const customFilterId = state.filter.filter_custom
        ? Object.keys(state.filter.filter_custom)[0]
        : undefined;
      let filter = state.filter;
      if (customFilterId) {
        const customFilter = state.filter.filter_custom[customFilterId];
        const parameter = customFilter.parameters[parameterId];
        const restValues = _omit(parameter.values, valueId);
        filter = {
          ...state.filter,
          filter_custom: {
            [customFilterId]: {
              ...customFilter,
              parameters: {
                ...customFilter.parameters,
                [parameterId]: { ...parameter, values: restValues },
              },
            },
          },
        };
      }

      return { ...state, filter };
    }
    case 'REMOVE_CUSTOM_FILTER_VALUE_FULFILLED': {
      const filter = action.payload.data.filter;
      const filterGo = convertToGoFilterStructure(action.payload.data.filter);
      return {
        ...state,
        filter,
        filterGo,
      };
    }
    case 'CREATE_APPLICATION_FILTER_PARAMETER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
        filterGo: convertToGoFilterStructure(action.payload.data.filter),
      };
    }
    case 'ADD_APPLICATION_FILTER_VALUE_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
        filterGo: convertToGoFilterStructure(action.payload.data.filter),
      };
    }
    case 'CREATE_CATEGORY_FILTER_PARAMETER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
        filterGo: convertToGoFilterStructure(action.payload.data.filter),
      };
    }
    case 'ADD_CATEGORY_FILTER_VALUE_FULFILLED': {
      return { ...state, filter: action.payload.data.filter };
    }
    case 'CREATE_ANALYSIS_FILTER_PARAMETER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
        filterGo: convertToGoFilterStructure(action.payload.data.filter),
      };
    }
    case 'ADD_ANALYSIS_FILTER_VALUE_FULFILLED': {
      return { ...state, filter: action.payload.data.filter };
    }
    case 'CREATE_CUSTOM_FILTER_PARAMETER_PENDING': {
      return {
        ...state,
        createParameterPending: true,
      };
    }
    case 'CREATE_CUSTOM_FILTER_PARAMETER_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
        filterGo: convertToGoFilterStructure(action.payload.data.filter),
        createParameterPending: false,
      };
    }
    case 'ADD_CUSTOM_FILTER_VALUE_FULFILLED': {
      return {
        ...state,
        filter: action.payload.data.filter,
        filterGo: convertToGoFilterStructure(action.payload.data.filter),
      };
    }
    case 'UPDATE_APPLICATION_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;
      return { ...state, filter, filterGo: convertToGoFilterStructure(filter) };
    }
    case 'UPDATE_CATEGORY_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;

      return { ...state, filter, filterGo: convertToGoFilterStructure(filter) };
    }
    case 'UPDATE_ANALYSIS_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;
      return { ...state, filter, filterGo: convertToGoFilterStructure(filter) };
    }
    case 'UPDATE_CUSTOM_FILTER_PARAMETER_FULFILLED': {
      const filter = action.payload.data.filter;
      return { ...state, filter, filterGo: convertToGoFilterStructure(filter) };
    }
    case 'UPDATE_TEMP_FILTER_FULFILLED': {
      const { filter } = action.payload.data;
      const { itemIds } = action.meta;
      return {
        ...state,
        filters: state.filters.map(f =>
          f.id === filter.id ? { ...f, item_ids: itemIds.join(',') } : f
        ),
        // Only set temp filter if it is not empty - in this case it will be hidden in the view
        filter: itemIds.length ? filter : state.filter,
      };
    }
    case 'FETCH_FILTER_ITEM_CATEGORIES_PENDING': {
      const page = action.meta.page;
      const level = action.meta.activeLevel;
      return {
        ...state,
        fetchingCategory: !!(level === 0 || (level === 1 && page > 1)),
        fetchingSubCategory: !!(level < 2 || (level === 2 && page > 1)),
        fetchingPartType: !!(level < 3 || (level === 3 && page > 1)),
        categories: level !== 0 ? state.categories : [],
        subCategories: level === 2 || level === 3 ? state.subCategories : [],
        partTypes: level === 3 ? state.partTypes : [],
      };
    }
    case 'FETCH_FILTER_ITEM_CATEGORIES_FULFILLED': {
      const response = action.payload.data;
      const page = action.meta.page;
      return {
        ...state,
        fetchingCategory: false,
        fetchingSubCategory: false,
        fetchingPartType: false,
        categories: page > 1 ? [...state.categories, ...response[1]] : response[1] || [],
        subCategories: page > 1 ? [...state.subCategories, ...response[2]] : response[2] || [],
        partTypes: page > 1 ? [...state.partTypes, ...response[3]] : response[3] || [],
      };
    }
    case 'FETCH_FILTER_MAKES_FULFILLED': {
      return { ...state, makes: action.payload.data };
    }
    case 'FETCH_FILTER_MODELS_FULFILLED': {
      return { ...state, models: action.payload.data };
    }
    case 'FETCH_FILTER_SUBMODELS_FULFILLED': {
      return { ...state, submodels: action.payload.data };
    }
    case 'FETCH_FILTER_REGIONS_FULFILLED': {
      return { ...state, regions: action.payload.data };
    }
    case 'FETCH_FILTER_YEARS_PENDING': {
      return { ...state, fetchingYears: true };
    }
    case 'FETCH_FILTER_YEARS_REJECTED': {
      return { ...state, fetchingYears: false };
    }
    case 'FETCH_FILTER_YEARS_FULFILLED': {
      return { ...state, years: action.payload.data, fetchingYears: false };
    }
    case 'FETCH_RANKED_APPLICATIONS_FOR_FILTER_FULFILLED': {
      return { ...state, rankedApplications: action.payload.data.sub_configs };
    }
    case 'FETCH_ANALYSES_ALERT_ITEM_COUNT_PENDING': {
      return { ...state, analysesAlertItemCount: undefined, fetchingAnalysesAlertItemCount: true };
    }
    case 'FETCH_ANALYSES_ALERT_ITEM_COUNT_FULFILLED': {
      return {
        ...state,
        analysesAlertItemCount: action.payload.data,
        fetchingAnalysesAlertItemCount: false,
      };
    }
    case 'SET_FILTER': {
      return {
        ...state,
        filter: action.payload,
        filterGo: action.payload ? convertToGoFilterStructure(action.payload) : action.payload,
      };
    }
    case 'SET_FILTER_GO': {
      return {
        ...state,
        filterGo: action.payload,
      };
    }
    case 'SELECT_BRAND': {
      // reset filter results on brand change
      return { ...state, items: [] };
    }
    case 'RESET_FILTER_FULFILLED': {
      const updatedFilter = action.payload.data.filter;
      const updatedFilters = state.filters.map(filter =>
        filter.id === updatedFilter.id ? updatedFilter : filter
      );
      return {
        ...state,
        filter: state.filter.id === updatedFilter.id ? updatedFilter : state.filter,
        filters: updatedFilters,
      };
    }
  }
  return state;
};

export default reducer;
